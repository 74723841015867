import styled from 'styled-components';

import ArrowComponent from 'components/svgs/Arrow';
import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Text = styled.div`
  transition: all 250ms ease-in;
`;
export const Content = styled.div`
  display: inline-flex;
  align-items: center;

  &:hover {
    ${Text} {
      transform: translateX(-10px);
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-left: auto;

  ${setTypography('paragraph')}
  font-weight: bold;
  line-height: 1.6rem;
  color: ${colors.gold};
  text-align: right;
`;

export const Arrow = styled(ArrowComponent)`
  width: 2.6rem;
  margin: 0 1.6rem;
`;
