import { useRouter } from 'next/router';
import React from 'react';

import * as Styled from './ActionLink.styles';

export interface ActionLinkProps {
  className?: string;
  children?: any;
  href: string;
}

const defaultProps: Partial<ActionLinkProps> = {};

const ActionLink: React.FC<ActionLinkProps> = ({
  className,
  children,
  href,
}: ActionLinkProps) => {
  const { push } = useRouter();

  return (
    <Styled.Wrapper onClick={() => push(href)} className={className}>
      <Styled.Content>
        <Styled.Text>{children}</Styled.Text>
        <Styled.Arrow />
      </Styled.Content>
    </Styled.Wrapper>
  );
};

ActionLink.defaultProps = defaultProps;

export default ActionLink;
