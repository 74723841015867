import { DocumentNode, gql } from 'apollo-boost';
import { head } from 'lodash';

export interface QueryBaseProps {
  locale: string | string[];
}

export const articlesQuery = (): DocumentNode => gql`
  {
    articlesSection {
      data {
        attributes {
          title
          ${headFragment}
        }
      }
    }
    articles {
      data {
        attributes {
          title
          thumbnail {
            data {
              attributes {
                url
              }
            }
          }
          slug
        }
      }
    }
    ${menuItemsFragment},
    ${socialMediaFragment},
    ${footerFragment},
    ${sharedComponentQuery},
  }
`;

export const aboutUsPage = () => gql`
{
  aboutUsPage {
    data {
      attributes {
        ${headFragment}
      }
    }
  }
}
`;

export const aboutUsQuery = (): DocumentNode => gql`
  {
    aboutUsPage {
      data {
        attributes {
          ${headFragment}
        }
      }
    }
    ${aboutUsFragment}
    ${menuItemsFragment},
    ${socialMediaFragment},
    ${footerFragment},
    ${sharedComponentQuery},
  }
`;

export const contactPageQuery = (): DocumentNode => gql`
  {
    ${menuItemsFragment},
    ${socialMediaFragment},
    ${footerFragment},
    ${contactInfoFragment},
    ${sharedComponentQuery},
  }
`;

export const articlePageQuery = (slug: any): DocumentNode => gql`
  {
    ${menuItemsFragment},
    ${socialMediaFragment},
    ${footerFragment},
    ${sharedComponentQuery},
    articles (filters: {slug: { eq: "${slug}"}}) {
      data {
        attributes {
          title
          createdAt
          shortDescription
          endActionText
          author
          slug
          ${headFragment}
          thumbnail {
            data {
              attributes {
                url
              }
            }
          }
          isFeatured
          content {
            ... on ComponentArticleImages {
              images {
                data {
                  attributes {
                    url
                  }
                }
              }
              orientation
              caption
            }
            ... on ComponentArticleParagraph {
              paragraph
            }
            ... on ComponentArticleSectionTitle {
              title
            }
          }
        }
      }
    }
  }
`;

export const articleSlugsQuery = (): DocumentNode => gql`
  {
    articles {
      data {
        attributes {
          slug
        }
      }
    }
  }
`;

export const priceListPageQuery = (): DocumentNode => gql`
  {
    servicePage {
      data {
        attributes {
          title
          subtitle
          ${headFragment}
          servicePackages {
            packagePrice
            bookingUrl
            dog {
              data {
                attributes {
                  name
                  Races
                  Description
                  order
                }
              }
            }
            key
            package {
              data {
                attributes {
                  name
                  services {
                    service {
                      data {
                        attributes {
                          name
                        }
                      }
                    }
                    price
                    chargedByHour
                  }
                }
              }
            }
          }
          faqTitle
        }
      }
    }
    faqs {
      data {
        attributes {
          question
          answer
          readMore {
            data {
              attributes {
                slug
              }
            }
          }
        }
      }
    }
    ${menuItemsFragment},
    ${socialMediaFragment},
    ${footerFragment},
    ${sharedComponentQuery},
  }
`;

export const landingPageQuery = (): DocumentNode => gql`
  {
	landingPage {
    data {
      attributes {
        ${headFragment}
        landingSectionTitle,
        landingSectionSubtitle,
        landingPageImages {
          ${mediaFragment}
        }
      }
    }
  }
  serviceSection {
    data {
      attributes {
        title,
        subtitle,
        servicesPackage {
          data {
            attributes {
              name
              services {
                service {
                  data {
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  articlesSection {
    data {
      attributes {
        title
      }
    }
  }
  articles (filters: { isFeatured: {eq: true}} ) {
    data {
      attributes {
        title
        thumbnail {
          data {
            attributes {
              url
            }
          }
        }
        slug
      }
    }
  }
  ${menuItemsFragment},
  ${socialMediaFragment},
  ${footerFragment},
  ${contactInfoFragment},
  ${aboutUsFragment}
  ${sharedComponentQuery}
}
`;

export const notFoundPageQuery = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    sharedCopy(locale: "${locale}"){
      data {
        attributes {
          global
        }
      }
    }
    notFoundPage(locale: "${locale}") {
      data {
        attributes {
          ${headFragment}
        }
      }
    }
  }
`;

export const headFragment = `
  ogTags {
    title,
    description,
    ogType,
    keywords,
    ogImage {
      data {
        attributes {
          url
        }
      }
    }
  }
`;

export const mediaFragment = `
data {
  attributes {
    url
  }
}
`;

export const socialMediaFragment = `
socialMedias {
  data {
    attributes {
      url
      platform
    }
  }
}
`;

export const menuItemsFragment = `
menuItems(sort: "order") {
  data {
    id
    attributes {
      name
      destination
      order
    }
  }
}
`;

export const contactInfoFragment = `
contactPage {
  data {
    attributes {
      title
      addressCopy
      address
      addressComment
      phoneCopy
      phone
      onlineBookingCopy
      mapEmbed
      ${headFragment}
    }
  }
}
`;

export const footerFragment = `
footer {
  data {
    attributes {
      companyTitle
      companyData
      deliveryTitle
      deliveryData
      contractor
    }
  }
}
`;

export const aboutUsFragment = `
aboutUs {
  data {
    attributes {
      title,
      sections {
        content,
        images {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`;

export const sharedComponentQuery = `
sharedComponent {
  data {
    attributes {
      signInCopy
      signInUrl
    }
  }
}
`;
