import { useInView } from 'framer-motion';
import React, { useRef } from 'react';

import * as Styled from './ServicePackage.styles';

export interface ServicePackageProps {
  className?: string;
  data: ServicePackageType;
}

const defaultProps: Partial<ServicePackageProps> = {};

const ServicePackage: React.FC<ServicePackageProps> = ({
  className,
  data,
  ...props
}: ServicePackageProps) => {
  const ref = useRef();
  const isInView = useInView(ref, { amount: 0.4 });

  return (
    <Styled.Wrapper
      ref={ref}
      animate={{ opacity: isInView ? 1 : 0 }}
      className={className}
      {...props}
    >
      <Styled.Title>{data.name}</Styled.Title>
      <Styled.Services>
        {data.services.map(service => (
          <Styled.Service key={`${data.name}-${service.service.name}`}>
            {service.service.name}
          </Styled.Service>
        ))}
      </Styled.Services>
    </Styled.Wrapper>
  );
};

ServicePackage.defaultProps = defaultProps;

export default ServicePackage;
