import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;

  background-color: ${colors.white};
  color: ${colors.black};
  border-radius: 5px;
  padding: 2rem;
  margin: 0 2rem;
  margin-bottom: 2vh;

  ${setTypography('body1')}
`;

export const Title = styled.div`
  font-weight: 700;
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Service = styled.div`
  margin-top: 1vh;
`;
